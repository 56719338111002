<template>
  <div>
    <Header :title="title" />
    <v-card elevation="2" class="mx-auto header-row" width="80%">
      <v-row class="ma-0 pa-0">
        <v-col :cols="12" :lg="8" :md="8" :sm="12" class="d-flex align-center"
          ><p class="py-0 pl-3 my-0 header-text text-white text-uppercase">
            {{ title }}
          </p></v-col
        >
        <v-col :cols="12" :lg="4" :md="4" :sm="12">
          <v-btn
            @click="languageChange('hu')"
            class="mr-3 ml-0 float-right"
            color="white"
            dark
            outlined
            elevation="0"
            >hu</v-btn
          >
          <v-btn
            @click="languageChange('en')"
            class="mr-1 ml-3 float-right"
            color="white"
            dark
            outlined
            elevation="0"
            >en</v-btn
          >
          <v-tooltip bottom class="float-right m-auto">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="white"
                dark
                outlined
                class="mx-3 float-right"
                v-bind="attrs"
                v-on="on"
                @click="excelExport"
              >
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <span>excel export</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined elevation="1" width="80%" class="mx-auto mt-5 pa-5 mb-5">
      <div class="modulbg px-3 rounded">
        <!-- <p class="information"><b>{{lang.kar}}:</b> {{samplePlan.department}}</p> -->
        <p class="" v-if="langName == 'hu'">
          <b>{{ lang.szak }}:</b> {{ samplePlan.faculty }}
        </p>
        <p class="" v-else>
          <b>{{ lang.szak }}:</b> {{ samplePlan_en.faculty }}
        </p>
        <p class="">
          <b>{{ lang.szakirany }}:</b> {{ samplePlan.specialization }}
        </p>
        <p class="">
          <b>{{ lang.mintaterv }}:</b> {{ samplePlan.samplePlan }}
        </p>
      </div>

      <v-row v-if="title != 'Általános' && !overlay">
        <v-col
          :cols="12"
          :lg="12"
          :md="12"
          :sm="12"
          v-for="(type, key) in subjects"
          :key="key"
        >
          <v-divider class="mx-4"></v-divider>
          <p>
            <b v-if="langName == 'hu'">{{ type.name }}</b>
            <b v-if="langName == 'en'">Mandatory subjects</b>
          </p>
          <p>
            {{ type.leiras }}
          </p>
          <v-simple-table class="border" v-if="type.children.length == 0">
            <template>
              <thead>
                <tr class="uni_blue2">
                  <th class="p-2 text-light border">
                    {{ lang.table_sorszam }}
                  </th>
                  <th class="p-2 text-light border">{{ lang.table_kod }}</th>
                  <th class="p-2 text-light border">
                    {{ lang.table_megnevezes }}
                  </th>
                  <th
                    class="p-2 text-light border"
                    v-if="samplePlan.type != 'Levelező'"
                  >
                    {{ lang.table_heti_elmelet }}
                  </th>
                  <th class="p-2 text-light border" v-else>
                    {{ lang.table_feleves_elmelet }}
                  </th>
                  <th
                    class="p-2 text-light border"
                    v-if="samplePlan.type != 'Levelező'"
                  >
                    {{ lang.table_heti_gyakorlat }}
                  </th>
                  <th class="p-2 text-light border" v-else>
                    {{ lang.table_feleves_gyakorlat }}
                  </th>
                  <th
                    class="p-2 text-light border"
                    v-if="samplePlan.type != 'Levelező'"
                  >
                    {{ lang.table_heti_labor }}
                  </th>
                  <th class="p-2 text-light border" v-else>
                    {{ lang.table_feleves_labor }}
                  </th>
                  <th class="p-2 text-light border">
                    {{ lang.table_beszamolas_fajtaja }}
                  </th>
                  <th class="p-2 text-light border">
                    {{ lang.table_kreditpont }}
                  </th>
                  <th class="p-2 text-light border">
                    {{ lang.table_ajanlott_felev }}
                  </th>
                  <th class="p-2 text-light border">
                    {{ lang.table_elotanulmanyi_kov }}
                  </th>
                  <th class="p-2 text-light border">
                    {{ lang.table_adminisztrativ }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(subject, index) in type.subjects" :key="index">
                  <td class="px-2 border">{{ index + 1 }}</td>
                  <td class="px-2 border">
                    <a
                      href="javascript:void(0)"
                      @click="tantargytematika(subject.Targykod)"
                      style="color: #0c3c60"
                      >{{ subject.Targykod }}</a
                    >
                  </td>
                  <td class="px-2 border">{{ subject.Targynev }}</td>
                  <td class="px-2 border" v-if="subject.F5 != null">
                    {{ subject.F5 }}
                  </td>
                  <td class="px-2 border" v-if="subject.F22 != null">
                    {{ subject.F22 }}
                  </td>
                  <td
                    class="px-2 border"
                    v-if="subject.F22 == null && subject.F5 == null"
                  >
                    0
                  </td>
                  <td class="px-2 border" v-if="subject.F7 != null">
                    {{ subject.F7 }}
                  </td>
                  <td class="px-2 border" v-if="subject.F23 != null">
                    {{ subject.F23 }}
                  </td>
                  <td
                    class="px-2 border"
                    v-if="subject.F7 == null && subject.F23 == null"
                  >
                    0
                  </td>
                  <td class="px-2 border" v-if="subject.F18 != null">
                    {{ subject.F18 }}
                  </td>
                  <td class="px-2 border" v-if="subject.F24 != null">
                    {{ subject.F24 }}
                  </td>
                  <td
                    class="px-2 border"
                    v-if="subject.F18 == null && subject.F24 == null"
                  >
                    0
                  </td>
                  <td class="px-2 border">{{ subject.F27 }}</td>
                  <td class="px-2 border">
                    <p v-if="subject.Targy_kredit != null">
                      {{ subject.Targy_kredit }}
                    </p>
                    <p v-if="subject.Targy_kredit == null">0</p>
                  </td>
                  <td class="px-2 border">{{ subject.Felev_szam }}</td>
                  <td class="px-2 border">
                    <p v-if="subject.Elokovetelmeny_leiras_1 != null">
                      {{ subject.Elokovetelmeny_leiras_1 }}
                    </p>

                    <p v-if="subject.Elokovetelmeny_leiras_2 != null">
                      {{ subject.Elokovetelmeny_leiras_2 }}
                    </p>
                  </td>
                  <td v-if="subject.admin.Targykod != ''">
                    <p>
                      {{ lang.table_admin_1 }} {{ subject.admin.Targykod }}
                      {{ lang.table_admin_2 }}
                    </p>
                  </td>
                  <td v-else></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-else class="ml-8">
            <div v-for="(child, childKey) in type.children" :key="childKey">
              <p>
                <b>{{ child.name }}</b>
              </p>
              <p>
                {{ child.leiras }}
              </p>
              <v-simple-table class="border" v-if="type.children.length != 0">
                <template>
                  <thead>
                    <tr class="uni_blue2">
                      <th class="p-2 text-light border">
                        {{ lang.table_sorszam }}
                      </th>
                      <th class="p-2 text-light border">
                        {{ lang.table_kod }}
                      </th>
                      <th class="p-2 text-light border">
                        {{ lang.table_megnevezes }}
                      </th>
                      <th
                        class="p-2 text-light border"
                        v-if="samplePlan.type != 'Levelező'"
                      >
                        {{ lang.table_heti_elmelet }}
                      </th>
                      <th class="p-2 text-light border" v-else>
                        {{ lang.table_feleves_elmelet }}
                      </th>
                      <th
                        class="p-2 text-light border"
                        v-if="samplePlan.type != 'Levelező'"
                      >
                        {{ lang.table_heti_gyakorlat }}
                      </th>
                      <th class="p-2 text-light border" v-else>
                        {{ lang.table_feleves_gyakorlat }}
                      </th>
                      <th
                        class="p-2 text-light border"
                        v-if="samplePlan.type != 'Levelező'"
                      >
                        {{ lang.table_heti_labor }}
                      </th>
                      <th class="p-2 text-light border" v-else>
                        {{ lang.table_feleves_labor }}
                      </th>
                      <th class="p-2 text-light border">
                        {{ lang.table_beszamolas_fajtaja }}
                      </th>
                      <th class="p-2 text-light border">
                        {{ lang.table_kreditpont }}
                      </th>
                      <th class="p-2 text-light border">
                        {{ lang.table_ajanlott_felev }}
                      </th>
                      <th class="p-2 text-light border">
                        {{ lang.table_elotanulmanyi_kov }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(subjectChild, indexChild) in child.subjects"
                      :key="indexChild"
                    >
                      <td class="px-2 border">{{ indexChild + 1 }}</td>
                      <td class="px-2 border">
                        <a
                          href="javascript:void(0)"
                          @click="tantargytematika(subjectChild.Targykod)"
                          style="color: #0c3c60"
                          >{{ subjectChild.Targykod }}</a
                        >
                      </td>
                      <td class="px-2 border">{{ subjectChild.Targynev }}</td>
                      <td class="px-2 border" v-if="subjectChild.F5 != null">
                        {{ subjectChild.F5 }}
                      </td>
                      <td class="px-2 border" v-if="subjectChild.F22 != null">
                        {{ subjectChild.F22 }}
                      </td>
                      <td
                        class="px-2 border"
                        v-if="
                          subjectChild.F22 == null && subjectChild.F5 == null
                        "
                      >
                        0
                      </td>
                      <td class="px-2 border" v-if="subjectChild.F7 != null">
                        {{ subjectChild.F7 }}
                      </td>
                      <td class="px-2 border" v-if="subjectChild.F23 != null">
                        {{ subjectChild.F23 }}
                      </td>
                      <td
                        class="px-2 border"
                        v-if="
                          subjectChild.F7 == null && subjectChild.F23 == null
                        "
                      >
                        0
                      </td>
                      <td class="px-2 border" v-if="subjectChild.F18 != null">
                        {{ subjectChild.F18 }}
                      </td>
                      <td class="px-2 border" v-if="subjectChild.F24 != null">
                        {{ subjectChild.F24 }}
                      </td>
                      <td
                        class="px-2 border"
                        v-if="
                          subjectChild.F18 == null && subjectChild.F24 == null
                        "
                      >
                        0
                      </td>
                      <td class="px-2 border">{{ subjectChild.F27 }}</td>
                      <td class="px-2 border">
                        <p v-if="subjectChild.Targy_kredit != null">
                          {{ subjectChild.Targy_kredit }}
                        </p>
                        <p v-if="subjectChild.Targy_kredit == null">0</p>
                      </td>
                      <td class="px-2 border">{{ subjectChild.Felev_szam }}</td>
                      <td class="px-2 border">
                        <p v-if="subjectChild.Elokovetelmeny_leiras_1 != null">
                          {{ subjectChild.Elokovetelmeny_leiras_1 }}
                        </p>

                        <p v-if="subjectChild.Elokovetelmeny_leiras_2 != null">
                          {{ subjectChild.Elokovetelmeny_leiras_2 }}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div v-if="child.credit != null && child.credit != ''">
                <p
                  v-if="
                    !(
                      (child.name ? child.name : '').includes('Idegen') ||
                      (child.name ? child.name : '').includes('Testnevelés') ||
                      (child.name ? child.name : '').includes('Kötelező tárgyak') ||
                      (child.name ? child.name : '').includes('(kötelező törzsanyag)') ||
                      (child.name ? child.name : '').includes('(Kötelező törzsanyag)')
                    )
                  "
                >
                  <b>{{ lang.elvegzett_credit }} {{ child.credit }}</b>
                </p>
                <p
                  v-if="
                    (child.name ? child.name : '').includes('Kötelező tárgyak') ||
                    (child.name ? child.name : '').includes('(kötelező törzsanyag)') ||
                    (child.name ? child.name : '').includes('(Kötelező törzsanyag)')
                  "
                >
                  <b>{{ lang.elvegzett_credit_kotelezo }} {{ child.credit }}</b>
                </p>
              </div>
              <p v-if="(child.name ? child.name : '').includes('Testnevelés')">
                <b
                  >{{ lang.elvegzett_credit_testneveles }}
                  {{ child.tantargyak_szama }}</b
                >
              </p>
              <p
                v-if="
                  child.credit == null &&
                  child.tantargyak_szama != null &&
                  !(child.name ? child.name : '').includes('Testnevelés')
                "
              >
                <b
                  >{{ lang.elvegzett_tantargy_csoport }}
                  {{ child.tantargyak_szama }}</b
                >
              </p>
            </div>
          </div>
          <div v-if="type.credit != null && type.credit != ''">
            <p
              v-if="
                !(
                  (type.name ? type.name : '').includes('Idegen') ||
                  (type.name ? type.name : '').includes('Testnevelés') ||
                  (type.name ? type.name : '').includes('Kötelező tárgyak') ||
                  (type.name ? type.name : '').includes('(kötelező törzsanyag)') ||
                  (type.name ? type.name : '').includes('(Kötelező törzsanyag)')
                )
              "
            >
              <b>{{ lang.elvegzett_credit }} {{ type.credit }}</b>
            </p>
            <p
              v-if="
                (type.name ? type.name : '').includes('Kötelező tárgyak') ||
                (type.name ? type.name : '').includes('(kötelező törzsanyag)') ||
                (type.name ? type.name : '').includes('(Kötelező törzsanyag)')
              "
            >
              <b>{{ lang.elvegzett_credit_kotelezo }} {{ type.credit }}</b>
            </p>
          </div>
          <p v-if="(type.name ? type.name : '').includes('Testnevelés')">
            <b
              >{{ lang.elvegzett_credit_testneveles }}
              {{ type.tantargyak_szama }}</b
            >
          </p>
          <p
            v-if="
              type.credit == null &&
              type.tantargyak_szama != null &&
              !(type.name ? type.name : '').includes('Testnevelés')
            "
          >
            <b
              >{{ lang.elvegzett_tantargy_csoport }}
              {{ type.tantargyak_szama }}</b
            >
          </p>
        </v-col>
      </v-row>
    </v-card>
    <div class="text-center">
      <v-dialog v-model="dialog" width="60%">
        <v-card>
          <v-card-text class="m-0 p-0">
            <TantargyTematika :subject="subject" :lang="lang" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-overlay :value="loadingCircular" opacity="0.9">
      <v-progress-circular
        indeterminate
        size="250"
        width="10"
        color="uni_blue2"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import TantargyTematika from "@/components/TantargyTematika.vue";
import Header from "@/components/Header.vue";
import hu from "@/plugins/hu.json";
import en from "@/plugins/en.json";
export default {
  props: ["mintaterv"],
  components: {
    Header,
    TantargyTematika,
  },
  data: () => ({
    title: "",
    lang: en,
    langName: "",
    type: "",
    selected: "",
    dialog: false,
    subjects: [],
    subject: {},
    samplePlan: {},
    overlay: false,
    loadingCircular: false,
    URLSamplePlan: {
      id: "",
    },
    samplePlan_en: {},
  }),
  async mounted() {
    this.loadingCircular = true;
    this.URLSamplePlan.id = this.$route.params.id;

    if (this.$route.params.lang == "en") {
      this.lang = en;
      this.$cookies.set("lang", "en");
    } else {
      this.lang = hu;
      this.$cookies.set("lang", "hu");
    }
    this.langName = this.$route.params.lang;
    try {
      const response = await this.$http.post(
        "getSubjects/",
        this.URLSamplePlan,
        { timeout: 6000 }
      );
      this.samplePlan = {
        department: "",
        faculty: response.information[0].F3,
        specialization: response.information[0].F7,
        samplePlan: response.information[0].F10,
        type: response.information[0].F4,
        level: response.information[0].F5,
      };
      this.samplePlan_en = {
        faculty: response.information[0].F16,
      }
      this.title = this.samplePlan.samplePlan;
      this.subjects = response.types;
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingCircular = false;
    }
  },
  methods: {
    languageChange(type) {
      if (type == "en") {
        this.lang = en;
      } else {
        this.lang = hu;
      }
      this.$cookies.set("lang", type);
      this.langName = type;
    },
    async tantargytematika(subjectCode) {
      try {
        const post = {
          sub: subjectCode,
        };
        const response = await this.$http.post("getSubjectDetails/", post);
        this.subject = response.details;
      } catch (e) {
        console.error(e);
      }

      this.dialog = true;
    },

    async excelExport() {
      try {
        this.loadingCircular = true;
        const response = await this.$http.post(
          "excelExport/",
          this.URLSamplePlan
        );
        const link = document.createElement("a");
        link.href = response.file_name;
        link.setAttribute("target", "_blank");
        link.setAttribute("download", response.file + ".xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingCircular = false;
      }
    },
  },
};
</script>

<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 25px;
}
.v-data-table td {
  text-align: center;
}
p {
  margin: 0.5rem 0;
}
.header-row {
  background-color: #0c3c60;
}
a {
  display: inline-block;
  position: relative;
  text-decoration: none;
  overflow: hidden;
}
a::after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 0.05em;
  transform: scaleX(0);
  background-color: #0c3c60;
  transform-origin: bottom right;
}
a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
  transition: transform 0.25s ease-in;
}

.modulbg {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 1px;
  background-color: #0c3c60;
  color: white;
}
.header-row {
  background: linear-gradient(135deg, #00adcb 20%, #0c3c60);
}
.header-text {
  font-size: 1.5rem;
  font-weight: 600;
}
</style>